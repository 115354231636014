import shopifyGenericDefaults from '../shopify-generic/config.js';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    getFitmentSearchTitle: () => '',
  },
};
