//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-708:_9932,_9100,_2644,_1424,_9288,_9048,_6024,_4552;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-708')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-708', "_9932,_9100,_2644,_1424,_9288,_9048,_6024,_4552");
        }
      }catch (ex) {
        console.error(ex);
      }